/*!
 * 应用编辑器全局数据
 * 专门用于应用编辑器数据绑定
 */
import { ACCESS_TOKEN, getToken, getUserInfo, USER_INFO } from '../utils/auth'

function loadGlobalState({ isClient, request }) {
  if (!isClient) {
    global.getToken = () => request.cookies?.[ACCESS_TOKEN]
    global.getUserInfo = () => request.cookies?.[USER_INFO]
  } else {
    window.getToken = getToken
    window.getUserInfo = getUserInfo
  }
  return {
    isAuth: !isClient ? !!global.getToken() : !!window.getToken(),
    userInfo: !isClient ? global.getUserInfo() : window.getUserInfo()
  }
}
export default loadGlobalState
