/*!
 * 应用编辑器全局数据
 * 专门用于应用编辑器数据绑定
 */

function loadGlobalState() {
  // to do ..
  return {
    // to do ..
  }
}
export default loadGlobalState
