import eventBus from './eventBus'
// import { defineAsyncComponent } from 'vue'
import { createProperty } from './createProperty'
/**
 * 刷新routerview的方法
 */
export function reloadPage() {
  eventBus.emit('reloadPage')
}
/**
 *
 */
/**
 * 初始化loading组件
 * @param {type}  component  -  loading组件,需要用异步加载组件的方式
 * @param {Object}  initProps  -  loading组件初始化属性
 * @param {func}  loadEndEvent  -  loading组件触发消失的事件
 */
export function initLoading(component, initProps, loadEndEvent) {
  let loading

  // const allModules = import.meta.globEager("@mid")
  const [com] = createProperty('loadingDialog', component)

  loading = com(initProps)

  eventBus.on('loadStart', (data) => {
    console.log('🚀 ~ start ~ data', data)
    loading = com(initProps)
  })

  eventBus.on('loadEnd', (data) => {
    console.log('🚀 ~ end ~ data', data)
    // loadingApp.visible = false
    try {
      loadEndEvent(loading)
    } catch (error) {
      console.log('loadEndEvent:', error)
    }

    loading.unmount()
  })

  return loading
}
