import { ACCESS_TOKEN, setUserInfo } from '@middleground/utils/auth'
import eventBus from '@/common/utils/eventBus'
import { Request } from '@tcc-ofa/render'
import { message } from '@lcdp-atom-lib/pc'

const { VITE_API_BASE_URL, VITE_API_DOMAIN } = import.meta.env
const baseURL = !import.meta.env.SSR
  ? VITE_API_BASE_URL
  : `${VITE_API_DOMAIN}${VITE_API_BASE_URL}`

const config = {
  config: { baseURL, timeout: 60000 },
  configResolved: (config) => {
    // const getToken = !SSR ? window.getToken : global.getToken
    // eslint-disable-next-line no-undef
    const token = getToken && getToken()
    config.params = {
      ...(config.params || {}),
      env: sessionStorage.getItem('render-env')
    }
    // 设置自定义求情头
    const reqHeader = JSON.parse(sessionStorage.getItem('reqHeader'))
    for (let key in reqHeader) {
      config.headers[key] = reqHeader[key]
    }
    token && (config.headers[ACCESS_TOKEN] = token)
    // config.headers['OFA-ORGID'] = '592078098849153024' // 临时加orgID,筋斗云使用，后面改成配置
    if (config.$loading) {
      eventBus.emit('loadStart', true)
    }
  },
  // 自定义错误处理
  onReject: (error, config) => {
    if (config.$loading) {
      eventBus.emit('loadEnd', true)
    }
    if (error.code === 401) {
      //检测到未登陆，先清空本地用户信息
      setUserInfo('{}')
    }

    if (config.$showError) {
      //  1.判断请求超时
      if (
        error.code === 'ECONNABORTED' &&
        error.message.indexOf('timeout') !== -1
      ) {
        message.error('当前业务繁忙响应超时，请稍后再试！')
        // return service.request(originalRequest);//例如再重复请求一次
      } else {
        message.error(error.message)
      }
    }
    return Promise.reject(error)
  },
  onResolve: (res) => {
    if (res.config.$loading) {
      eventBus.emit('loadEnd', true)
    }
    return Promise.resolve(res)
  }
}
export const renderRequest = new Request(config)
const request = new Request({
  ...config,
  onResolve: (res) => Promise.resolve(res.data)
})
export default request
