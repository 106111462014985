/**
 * 获取当前目录下的所有组件
 * @type { *[] }
 */

let moduleComponents = [] //组件注册数据
let moduleConfig = [] //组件提供给编辑器的配置

const files = import.meta.globEager('./**/*.props.js')

for (const key in files) {
  if (Object.prototype.hasOwnProperty.call(files, key)) {
    if (key === './demo/demo.props.js') continue //过滤掉demo组件
    moduleConfig = [...moduleConfig, files[key].default || files[key]]
  }
}

moduleConfig = [
  {
    // label: '', //该字段用作分类名称，不定义则判定为未分类，统一放置在最前面,
    group: 'general', //该字段用来区分是定制的通用组件还是定制组件
    children: moduleConfig // 该分类的组件列表配置
  }
]
//获取组件注册文件
const comsFiles = import.meta.globEager('./**/*.js')

for (const key in comsFiles) {
  if (Object.prototype.hasOwnProperty.call(comsFiles, key)) {
    if (key === './demo/demo.js') continue //过滤掉demo组件
    moduleComponents = [
      ...moduleComponents,
      comsFiles[key].default || comsFiles[key]
    ]
  }
}

export { moduleComponents, moduleConfig }
