/*!
 * 应用编辑器全局数据
 * 专门用于聚合 projects 下的供应用编辑器数据绑定的全局变量
 */

// 中台工程
import middlegroundLoadGlobalState from '@/projects/middleground/editorInteract/globalState'

// 业务示例工程
import businessLoadGlobalState from '@/projects/business/editorInteract/globalState'

// 聚合
function loadGlobalState({ app, router, isClient, initialState, request }) {
  return Object.assign(
    // 中全局变量
    middlegroundLoadGlobalState({
      app,
      router,
      isClient,
      initialState,
      request
    }),
    businessLoadGlobalState({
      app,
      router,
      isClient,
      initialState,
      request
    })
    // to do ..
  )
}

// 导出
export default loadGlobalState
