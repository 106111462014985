import 'ant-design-vue/dist/antd.css'
import '@tcc-ofa/render/dist/style.css'
import '@/aggregator/styles/editor-css.scss'
import '@lcdp-atom-lib/pc/dist/pc.css'
import { createHead } from '@vueuse/head'
import createViteSSR from 'ofa-vite-ssr'
import * as Components from '@lcdp-atom-lib/pc'
import { allComponents as customComponents } from '@/aggregator/components'
import App from './App.vue'
import { reactive } from 'vue'
import { routes, useRouterHook } from '@/aggregator/router'
import loadGlobalState from '@/aggregator/globalState'
import { loadPlatformEvents } from '@/aggregator/platformEvents'
import { setupI18n } from '@tcc-ofa/render'
import _request from '@/common/api/hooks/request'

export default createViteSSR(
  App,
  {
    routes,
    routerOptions: {
      scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition
        }
        return { top: 0 }
      }
    }
  },
  /**
   * 异步调用函数，触发时机为在应用初始化完成前
   * @param {type}  app  -  当前应用实例
   * @param {type}  router  -  当前路由对象
   * @param {type}  isClient  -  是否是客户端的标识
   * @param {type}  initialState  -  页面设置的初始化变量信息
   * @param {type}  request  -  服务端请求，在客户端的时候为null
   */
  async ({ app, router, isClient, initialState, request }) => {
    //设置页面SEO信息
    const head = createHead()
    app.use(head)

    // const i18n = setupI18n({ locale: 'zh-CN' }, head)
    // app.use(i18n)
    setupI18n(
      app,
      { locale: 'zh-CN', message: [] },
      { head, router, appId: APP_ID, request: _request }
    )

    //设置页面初始化变量
    app.provide('initialState', initialState)
    // 初始化一个全局变量
    const globalState = reactive(
      loadGlobalState({ app, router, isClient, initialState, request })
    )
    app.provide('globalState', globalState)

    // 处理页面刷新的变量
    // const isReload = ref(false)
    // app.provide('isReload', isReload)
    // app.provide('reloadPage', () => (isReload.value = true))

    //调用聚合器事件回调
    const events = reactive(
      loadPlatformEvents({
        app,
        router,
        isClient,
        initialState,
        request,
        globalState
      })
    )
    app.provide('platformEvents', events)

    //注册原子组件库的所有组件
    Object.values(Components).forEach((component) => {
      app.use(component)
    })

    //注册当前工程的所有定制组件
    Object.values(customComponents).forEach((component) => {
      app.use(component)
    })

    //等待路由拦截器的执行
    await useRouterHook(router, initialState, globalState)
    console.log('app', app)
    //返回页面的SEO内容
    return { head }
  }
)
