/*!
 * 应用编辑器自定义事件
 * 专门用于应用编辑器事件操作
 */

import { initLoading } from '@/common/utils/app'
import { message } from 'ant-design-vue'
import { defineAsyncComponent } from 'vue'
/**
 * 触发时机为在应用初始化完成前
 * @param {type}  app  -  当前应用实例
 * @param {type}  router  -  当前路由对象
 * @param {type}  isClient  -  是否是客户端的标识
 * @param {type}  initialState  -  页面设置的初始化变量信息
 * @param {type}  request  -  服务端请求，在客户端的时候为null
 * @param {type}  globalState  -  全局变量
 */
function loadPlatformEvents({
  app,
  router,
  isClient,
  initialState,
  request,
  globalState
}) {
  console.log(app, router, isClient, initialState, request, globalState)

  initLoading(
    defineAsyncComponent(() =>
      import('@middleground/components/loading/Loading.vue')
    ),
    {
      visible: true
    },
    (e) => {
      e.visible = false
    }
  )

  //全局提示只能同时存在一个
  message.config({
    top: `100px`,
    duration: 3,
    maxCount: 1
  })

  return {
    // 自定义方法
    'message.success': (data, event, { content }) => {
      message.success(content)
    },
    'message.error': (data, event, { content }) => {
      message.error(content)
    },
    'message.warn': (data, event, { content }) => {
      message.warn(content)
    },
    'message.info': (data, event, { content }) => {
      message.info(content)
    }
  }
}
export default loadPlatformEvents
