<template>
  <ofa-render-app
    v-if="pageConfig?.contentPro"
    :page-config="pageConfig"
    :request="request"
  />
</template>

<script>
import { RenderApp as OfaRenderApp, usePageConfig } from '@tcc-ofa/render'
import request, { renderRequest } from '@/common/api/hooks/request'
export default {
  name: 'RenderApp',
  components: {
    OfaRenderApp
  },
  props: {
    pageId: String
  },
  setup(props) {
    const pageConfig = usePageConfig(props.pageId)
    const request = renderRequest
    console.log('pageConfig', pageConfig)
    return { pageConfig, request: (options) => request.request(options) }
  }
}
</script>

<style scoped lang="scss">
.layout {
  margin: 0;
  padding: 0;
}
</style>
