// 导入中台 所有 middleground 项目的路由 及其 路由钩子
import {
  routes as middlegroundRoutes,
  routerHook as middlegroundRouterHook
} from '@/projects/middleground/router'

// 导入所有 customDemo 项目的路由 及其 路由钩子
import {
  routes as customDemoRoutes,
  routerHook as customDemoRouterHook
} from '@/projects/business/router'

// 聚合路由
let routes = [...middlegroundRoutes, ...customDemoRoutes]

// 聚合路由钩子
const useRouterHook = async (router, initialState, globalState) => {
  await middlegroundRouterHook(router, initialState, globalState)
  await customDemoRouterHook(router, initialState, globalState)
}

// 导出
export { routes, useRouterHook }
