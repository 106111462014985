import { createApp, inject } from 'vue'

const getPropertyKey = (name) => `${name}Property`

export function useProperty(name) {
  return inject(getPropertyKey(name))
}

// const instanceMap = new WeakMap()
export const createProperty = (name, Component) => {
  const key = getPropertyKey(name)
  function create(options) {
    const instance = createApp(Component, options)
    let root = document.getElementById(key)
    if (!root) {
      //不存在该ID的标签时才创建该节点
      root = document.createElement('div')
      root.id = getPropertyKey(name)
      document.body.appendChild(root)
    }
    instance.mount(root)
    return instance
  }
  const plugin = {
    install: (app) => {
      app.provide(key, create)
      app.config.globalProperties[`$${name}`] = create
    }
  }
  return [create, plugin]
}
