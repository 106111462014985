/*!
 * 应用编辑器自定义事件
 * 专门用于应用编辑器事件操作
 */
import { defineAsyncComponent } from 'vue'
import { createProperty } from '@/common/api/hooks/createProperty'
import { setToken, setUserInfo } from '../utils/login'
import { message } from 'ant-design-vue'
import { reloadPage } from '@/common/utils/app'
import { initLoading } from '@/common/utils/app'
/**
 * 触发时机为在应用初始化完成前
 * @param {type}  app  -  当前应用实例
 * @param {type}  router  -  当前路由对象
 * @param {type}  isClient  -  是否是客户端的标识
 * @param {type}  initialState  -  页面设置的初始化变量信息
 * @param {type}  request  -  服务端请求，在客户端的时候为null
 * @param {type}  globalState  -  全局变量
 */
function loadPlatformEvents({
  app,
  router,
  isClient,
  initialState,
  request,
  globalState
}) {
  console.log(app, router, isClient, initialState, request)

  //如果需要自行定制loading样式请参考如下写法
  // ------- 定制loading  start --------
  initLoading(
    defineAsyncComponent(() =>
      import('@business/components/loading/Loading.vue')
    ),
    {
      visible: true
    },
    (e) => {
      console.log('custom-loading', e)
      e.visible = false
    }
  )
  // ------- 定制loading  end --------

  let loginApp
  const [loginDialog] = createProperty(
    'loginDialog',
    defineAsyncComponent(() => import('../components/login/Login.vue'))
  )

  return {
    // 自定义方法
    //自定义方法：登录
    login: () => {
      //判断当前是否已经挂载该组件，如果已挂载则直接返回
      if (!isClient || loginApp) return
      const onSuccess = (info) => {
        setUserInfo(info)
        console.log(info)
        // setToken(token)
        // axios
        //   .get('/tcc-user-center/client-api/userCenter/infoEnc')
        //   .then((res) => {
        //     console.log(res, '用户信息')
        //     if (res.data?.headPic?.indexOf('http') == -1) {
        //       res.data.headPic =
        //         process.env.VITE_OSS_DOMAIN + res.data.headPic
        //       setUserInfo(res.data)
        //     } else {
        //       setUserInfo(res.data)
        //     }
        //     globalState.userInfo = res.data
        //   })
        //   .catch((err) => {
        //     console.log(err)
        //   })
        globalState.isAuth = true
        loginApp.unmount()
        loginApp = null
        reloadPage()
      }

      const onClose = () => {
        loginApp.unmount()
        loginApp.visible = false
        loginApp = null
        console.log(loginApp, '点击了')
      }

      loginApp = loginDialog({
        visible: true,
        onSuccess,
        onClose
      })
    },
    //自定义方法：注销
    logout: (data, event, { needReload }) => {
      message.success('注销成功')
      if (isClient && needReload) {
        reloadPage()
        window.location.reload()
      }
      setToken('')
      setUserInfo('{}')
      globalState.userInfo = {}
      globalState.isAuth = false
    }
  }
}
/**
 * 此配置可让编辑器选择对应事件做绑定
 * label 对应显示的名称
 * value 对应自定义事件的方法名
 */
let loadEditorConfig = [
  { label: '登录', value: 'login' },
  { label: '注销', value: 'logout' }
]

export {
  loadPlatformEvents as businessPlatformEvents,
  loadEditorConfig as businessEditorConfig
}
