<template>
  <RouterView v-if="!isReload" v-slot="{ Component }">
    <Suspense>
      <component :is="Component" :key="$route.fullPath" />
    </Suspense>
  </RouterView>
</template>

<script>
import { defineComponent, nextTick, watchEffect, ref } from 'vue'
import eventBus from '@/common/utils/eventBus'
export default defineComponent({
  setup() {
    const isReload = ref(false)
    eventBus.on('reloadPage', () => {
      isReload.value = true
    })
    watchEffect(() => {
      if (isReload.value) {
        console.log('isReload')
        nextTick(() => (isReload.value = false))
      }
    })
    return { isReload }
  }
})
</script>

<style lang="scss">
#app {
  height: 100%;
}
#app .ofa-ide-page .ofa-ide-page {
  height: auto;
  .ofa-ide-page__content {
    overflow: visible;
  }
}
</style>
